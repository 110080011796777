import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Quote = makeShortcode("Quote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "parenting-through-the-pandemic-navigating-the-new-normal"
    }}>{`Parenting through the pandemic: Navigating the 'new normal'`}</h1>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/2-winning-from-home.svg" alt="pandemic home image" mdxType="SingleImage" />
    <Quote quote="“When things don’t go according to plan, plan according to how things go.”" author="Kayambila Mpulamasaka" mdxType="Quote" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      